import React from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import emailIcon from "@iconify/icons-mdi/email";
import cellphoneIcon from "@iconify/icons-mdi/cellphone";

const FooterSection = styled.footer`
  min-height: 80px;
  display: flex;
  align-items: center;
  padding: 50px 0 25px 0;
  background-color: #f2f2f2;

  @media (min-width: 992px) {
    padding: 80px 0 25px 0;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  margin: 0 15px;
  padding: 15px 0;

  @media (min-width: 992px) {
    padding: 0;
    margin: 0 8%;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterTop = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const ContactLink = styled.a`
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: ${({ theme }) => theme.violet};
  font-family: ${({ theme }) => theme.fontFamilyHeading};
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.headingM};
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-bottom: 15px;

    @media (min-width: 992px) {
      margin-bottom: 0;
      margin-right: 50px;
    }
  }

  svg {
    margin-right: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.violet};
    transform: translateX(-100%);
    transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  }

  &:hover:before {
    transform: translateX(0);
  }

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.headingL};
  }
`;

const Logo = styled.div`
  margin-bottom: 15px;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

const Copyrights = styled.p``;

const Footer = () => {
  return (
    <FooterSection>
      <FooterContainer>
        <FooterTop>
          <ContactLink href="mailto:kontakt@codected.eu">
            <Icon icon={emailIcon} color="#530ceb" />
            kontakt@codected.eu
          </ContactLink>
          <ContactLink href="tel:883777642">
            <Icon icon={cellphoneIcon} color="#530ceb" />
            883 777 642
          </ContactLink>
        </FooterTop>
        <FooterBottom>
          <Logo>
            <svg width="173" height="31" viewBox="0 0 173 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_258_3)">
                <path
                  d="M20.2628 29.5899C18.5063 28.6749 17.0522 27.2833 16.0723 25.5794C15.0244 23.7199 14.4979 21.6187 14.5471 19.492C14.5002 17.3628 15.0344 15.2603 16.094 13.4046C17.0877 11.6901 18.5599 10.2931 20.3354 9.37978C22.1918 8.42651 24.2598 7.9441 26.3525 7.9761C28.4464 7.94456 30.5154 8.42692 32.3733 9.37978C34.1445 10.2969 35.6124 11.6947 36.6038 13.4082C37.6604 15.2649 38.1934 17.3669 38.1471 19.4956C38.1934 21.6269 37.6525 23.7308 36.582 25.5829C35.5742 27.2896 34.0959 28.6791 32.3188 29.5899C30.4539 30.5412 28.3794 31.0234 26.2799 30.9935C24.1866 31.0303 22.1175 30.5476 20.2628 29.5899V29.5899ZM29.657 23.6171C30.5939 22.6622 31.0612 21.2848 31.0587 19.4848C31.0563 17.6849 30.6024 16.3074 29.697 15.3526C29.2673 14.8977 28.7478 14.5345 28.1704 14.2851C27.593 14.0357 26.97 13.9055 26.3396 13.9025C25.7093 13.8994 25.085 14.0237 24.5052 14.2675C23.9253 14.5114 23.4023 14.8697 22.9681 15.3203C22.0724 16.2752 21.6246 17.6586 21.6246 19.4705C21.6246 21.2824 22.0652 22.6598 22.9464 23.6027C23.3597 24.0654 23.8706 24.4333 24.4433 24.6807C25.016 24.9281 25.6367 25.0491 26.2618 25.0351C26.8968 25.0461 27.5271 24.9256 28.112 24.6813C28.6968 24.4371 29.2232 24.0745 29.657 23.6171V23.6171Z"
                  fill="#1E1E20"
                />
                <path
                  d="M21.5773 23.6423C20.8946 23.2842 20.2046 22.9261 19.5546 22.568H14.8884C14.2638 24.2797 13.0533 25.1343 11.257 25.1319C10.6667 25.15 10.0806 25.0265 9.54934 24.772C9.01803 24.5175 8.5573 24.1397 8.20672 23.6709C7.44656 22.6922 7.06769 21.2945 7.07011 19.4778C7.07253 17.6611 7.4514 16.2646 8.20672 15.2883C8.55662 14.819 9.01731 14.441 9.54885 14.1871C10.0804 13.9331 10.6667 13.8109 11.257 13.8309C11.8204 13.8228 12.3793 13.9301 12.8984 14.146C13.1645 13.4365 13.4925 12.7512 13.8788 12.0978C14.5636 10.9267 15.4327 9.87038 16.4535 8.96813C14.8497 8.28485 13.1163 7.94787 11.3696 7.97983C9.30721 7.9399 7.26901 8.42325 5.45054 9.3835C3.75185 10.3084 2.36271 11.7006 1.45245 13.3904C0.452316 15.2651 -0.0475475 17.36 -8.2858e-05 19.4778C-0.0469088 21.5955 0.452917 23.6902 1.45245 25.5652C2.37101 27.2583 3.76889 28.6517 5.47596 29.5757C7.29507 30.5343 9.33282 31.0175 11.395 30.9793C14.2081 30.9913 16.583 30.2477 18.5197 28.7485C20.0675 27.5356 21.2275 25.9081 21.8605 24.0612C21.7516 23.9144 21.6644 23.7855 21.5773 23.6423Z"
                  fill="#1E1E20"
                />
                <path
                  d="M40.068 13.3902C40.8607 11.746 42.1081 10.3558 43.6667 9.37974C45.1899 8.44134 46.9545 7.95413 48.7505 7.97606C50.1681 7.95973 51.5694 8.27512 52.8394 8.89633C54.0136 9.46657 55.0154 10.3309 55.7444 11.4029V0.99707H62.7021V30.6748H55.7444V27.4521C55.0718 28.5431 54.1084 29.4312 52.9592 30.0195C51.6605 30.6838 50.2132 31.0151 48.7505 30.9827C46.9511 31.0002 45.1851 30.5026 43.6667 29.5504C42.1079 28.5574 40.8616 27.1547 40.068 25.5005C39.1521 23.6008 38.6994 21.5162 38.7462 19.4132C38.7046 17.3314 39.1572 15.2688 40.068 13.3902V13.3902ZM54.3028 15.4349C53.8478 14.9681 53.3022 14.5963 52.6985 14.3416C52.0948 14.087 51.4453 13.9548 50.7887 13.9528C50.1321 13.9507 49.4817 14.079 48.8764 14.33C48.2711 14.5809 47.7232 14.9494 47.2653 15.4134C46.2969 16.3922 45.8128 17.7361 45.8128 19.4454C45.8128 21.1546 46.2969 22.5118 47.2653 23.5168C47.7177 23.989 48.2632 24.3653 48.8682 24.6224C49.4733 24.8795 50.1252 25.0122 50.7841 25.0122C51.4429 25.0122 52.0948 24.8795 52.6999 24.6224C53.3049 24.3653 53.8504 23.989 54.3028 23.5168C55.2712 22.5285 55.7553 21.1785 55.7553 19.4669C55.7553 17.7552 55.2712 16.4113 54.3028 15.4349V15.4349Z"
                  fill="#1E1E20"
                />
                <path
                  d="M87.3078 21.1284H71.566C71.675 22.5225 72.1289 23.586 72.9278 24.3189C73.731 25.0572 74.797 25.4534 75.8946 25.4218C77.6303 25.4218 78.8408 24.7056 79.5259 23.2733H86.9302C86.5545 24.7318 85.8522 26.0887 84.8748 27.2444C83.8548 28.4344 82.5715 29.3776 81.1237 30.0017C79.5492 30.686 77.8438 31.0279 76.1233 31.0043C74.0543 31.0412 72.0098 30.5581 70.1825 29.6006C68.4636 28.6776 67.0514 27.2851 66.1154 25.5901C65.1127 23.7163 64.6127 21.6208 64.6629 19.5027C64.6176 17.3852 65.1174 15.2909 66.1154 13.4154C67.036 11.7164 68.4427 10.3218 70.1607 9.40485C71.9935 8.48863 74.0183 8.00789 76.0732 8.00105C78.1281 7.99421 80.1561 8.46146 81.9952 9.36546C83.6783 10.2558 85.0652 11.6061 85.9897 13.2542C86.9847 15.0612 87.4853 17.0925 87.4422 19.1482C87.4348 19.8102 87.39 20.4713 87.3078 21.1284V21.1284ZM80.3103 17.3184C80.3327 16.7903 80.2348 16.2639 80.0236 15.7781C79.8125 15.2922 79.4935 14.8593 79.0901 14.5111C78.2394 13.803 77.1524 13.4317 76.0398 13.4691C74.966 13.4385 73.9176 13.7949 73.0912 14.4717C72.2571 15.2076 71.7282 16.2214 71.606 17.3184H80.3103Z"
                  fill="#1E1E20"
                />
                <path
                  d="M89.4432 13.3901C90.3597 11.6954 91.7581 10.3016 93.4667 9.3796C95.2852 8.41935 97.3234 7.936 99.3858 7.97592C102.233 7.97592 104.612 8.71118 106.525 10.1817C108.437 11.6522 109.691 13.7231 110.287 16.3944H102.883C102.258 14.6828 101.048 13.827 99.2514 13.827C98.6605 13.8078 98.0737 13.9311 97.5421 14.1864C97.0106 14.4416 96.5502 14.821 96.2011 15.2915C95.4434 16.2655 95.0645 17.662 95.0645 19.4811C95.0645 21.3001 95.4531 22.699 96.2302 23.6778C96.5807 24.1465 97.0415 24.5243 97.5728 24.7788C98.1041 25.0333 98.6901 25.1568 99.2805 25.1387C101.07 25.1387 102.28 24.2841 102.912 22.5749H110.316C109.718 25.2008 108.457 27.2586 106.532 28.7482C104.608 30.2378 102.235 30.9874 99.4148 30.9969C97.3526 31.0351 95.3149 30.5518 93.4958 29.5933C91.7887 28.6693 90.3908 27.2759 89.4723 25.5828C88.4727 23.7078 87.9729 21.6131 88.0197 19.4954C87.9602 17.3741 88.4501 15.2728 89.4432 13.3901V13.3901Z"
                  fill="#1E1E20"
                />
                <path
                  d="M124.918 24.8597V30.6749H121.381C118.86 30.6749 116.895 30.0662 115.483 28.8487C114.072 27.6312 113.367 25.6463 113.37 22.8938V13.9919H110.61V8.29488H113.377V2.84131H120.331V8.29488H124.885V13.9919H120.331V22.9762C120.308 23.2351 120.339 23.496 120.423 23.7425C120.507 23.9889 120.641 24.2157 120.818 24.4085C121.145 24.7021 121.686 24.849 122.445 24.849L124.918 24.8597Z"
                  fill="#1E1E20"
                />
                <path
                  d="M148.354 21.1284H132.612C132.721 22.5225 133.175 23.586 133.974 24.3189C134.773 25.0528 135.831 25.4487 136.923 25.4218C138.659 25.4218 139.869 24.7056 140.554 23.2733H147.958C147.583 24.7318 146.88 26.0887 145.903 27.2444C144.888 28.4322 143.611 29.3753 142.17 30.0017C140.596 30.686 138.89 31.0279 137.17 31.0043C135.101 31.0412 133.056 30.5581 131.229 29.6006C129.51 28.6776 128.098 27.2851 127.162 25.5901C126.159 23.7163 125.659 21.6208 125.709 19.5027C125.664 17.3852 126.164 15.2909 127.162 13.4154C128.082 11.7164 129.489 10.3218 131.207 9.40485C133.04 8.48863 135.065 8.00789 137.12 8.00105C139.174 7.99421 141.203 8.46146 143.042 9.36546C144.725 10.2558 146.112 11.6061 147.036 13.2542C148.032 15.0608 148.533 17.0924 148.489 19.1482C148.481 19.8102 148.436 20.4713 148.354 21.1284V21.1284ZM141.357 17.3184C141.379 16.7903 141.281 16.2639 141.07 15.7781C140.859 15.2922 140.54 14.8593 140.137 14.5111C139.286 13.803 138.199 13.4317 137.086 13.4691C136.012 13.4385 134.964 13.7949 134.138 14.4717C133.304 15.2076 132.775 16.2214 132.652 17.3184H141.357Z"
                  fill="#1E1E20"
                />
                <path
                  d="M150.366 13.3902C151.16 11.7455 152.408 10.3553 153.968 9.37974C155.491 8.44134 157.256 7.95413 159.052 7.97606C160.47 7.95973 161.871 8.27512 163.141 8.89633C164.314 9.46797 165.316 10.332 166.046 11.4029V0.99707H173V30.6748H166.046V27.4521C165.371 28.5426 164.407 29.4304 163.257 30.0195C161.961 30.6882 160.515 31.0244 159.052 30.9971C157.253 31.0145 155.487 30.517 153.968 29.5647C152.409 28.5717 151.163 27.169 150.37 25.5149C149.454 23.6151 149.001 21.5305 149.048 19.4275C149.003 17.3411 149.454 15.2734 150.366 13.3902V13.3902ZM164.601 15.4349C164.146 14.968 163.6 14.5963 162.996 14.3416C162.393 14.087 161.743 13.9548 161.087 13.9527C160.43 13.9507 159.78 14.079 159.174 14.33C158.569 14.5809 158.021 14.9494 157.563 15.4134C156.595 16.3921 156.111 17.7361 156.111 19.4454C156.111 21.1546 156.595 22.5117 157.563 23.5168C158.016 23.989 158.561 24.3653 159.166 24.6224C159.771 24.8795 160.423 25.0122 161.082 25.0122C161.741 25.0122 162.393 24.8795 162.998 24.6224C163.603 24.3653 164.148 23.989 164.601 23.5168C165.569 22.5285 166.053 21.1785 166.053 19.4669C166.053 17.7552 165.569 16.4112 164.601 15.4349Z"
                  fill="#1E1E20"
                />
              </g>
              <defs>
                <clipPath id="clip0_258_3">
                  <rect width="173" height="30" fill="white" transform="translate(0 0.99707)" />
                </clipPath>
              </defs>
            </svg>
          </Logo>
          <Copyrights>© Codected.eu Wszelkie prawa zastrzeżone</Copyrights>
        </FooterBottom>
      </FooterContainer>
    </FooterSection>
  );
};

export default Footer;
